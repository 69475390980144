<template>
  <header>
    <div class="img-box">
      <img src="/media/zainh_CMS.webp" alt="" />
      <span class="mezian-logo">
        <img src="/media/mezian.png" alt="" />
        <span>تطوير مزيان للتكنولوجيا</span>
      </span>
    </div>
  </header>

  <main>
    <div class="container">
      <Form class="login-form" @submit="handleLogin">
        <h2>دخول إلى لوحة التحكم</h2>
        <div class="form-group">
          <label for="username">اسم المستخدم</label>
          <Field
              name="email"
              id="username"
              type="text"
              class="form-control"
              v-model="email"
              :rules="validateRequired"
          />
          <ErrorMessage name="email" class="text-danger mt-1 d-inline-block" />
        </div>

        <div class="form-group">
          <label for="password">كلمة المرور</label>
          <Field
              autocomplete="on"
              name="password"
              id="password"
              type="password"
              class="form-control"
              v-model="password"
              :rules="validateRequired"
          />
          <ErrorMessage
              name="password"
              class="text-danger mt-1 d-inline-block"
          />
        </div>

        <div>
          <button class="btn" type="submit" :disabled="loading">دخول</button>
          <a href="" class="text-decoration-none">هل نسيت كلمة المرور</a>
        </div>
      </Form>
    </div>
  </main>
</template>

<script setup>
import {ref} from 'vue';
import { Form, Field, ErrorMessage } from "vee-validate";
import {useStore} from 'vuex';
import { useToast } from "vue-toastification";
import { login } from "@/services/auth";
import {useRouter} from 'vue-router';

const router = useRouter();
const store = useStore()
const toast = useToast()
const email = ref('')
const password = ref('')
const loading = ref(false)

const validateRequired = value => {
  if (!value.trim()) {
    return "هذا الحقل مطلوب";
  }
  return true;
}

const handleLogin = async values => {
  try
  {
    loading.value = true
    const result = await login(values);
    let token = await result.data.access_token;
    let type_id = await result.data.type_id;
    localStorage.setItem("access_token", JSON.stringify(token));
    localStorage.setItem("type_id", type_id);
    await store.dispatch('fetchUser', result.data)
    toast.success("تم تسجيل الدخول بنجاح", {
      position: "top-center",
    })
    await router.push('/')
  }catch (e) {
    console.log(e)
    loading.value = false
    toast.error("خطأ في اسم المستخدم أو كلمة المرور");
  }

}
</script>

<style scoped lang="scss">
header {
  height: 340px;
  background-color: #4b5caf;
  background-image: url("/media/logo-background.png");
  background-repeat: no-repeat;
  background-position: 80%;
  background-size: contain;

  .img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 1.25rem;
  }

  img {
    width: 252px;
    height: 62px;
    max-width: 300px;
  }

  .mezian-logo {
    color: #ffffff;
    position: relative;
    text-align: center;

    img {
      object-fit: contain;
      width: 60px;
      height: auto;
    }

    span {
      margin-top: 0.35rem;
      display: block;
      font-size: 0.875rem;
    }

    &:before {
      content: "";
      position: absolute;
      height: 80%;
      top: 10%;
      width: 2px;
      background-color: #7a8bc6;
      right: -2.5rem;
    }
  }

  @media (max-width: 768px) {
    .img-box {
      padding-top: 2rem;
      gap: 1rem;
      flex-direction: column;
      height: unset;
    }
    .mezian-logo {
      &:before {
        display: none;
      }
    }
  }
}

.login-form {
  position: relative;
  top: -5rem;
  max-width: 465px;
  padding: 1rem 2rem 2.5rem;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px -1px 8px #f3f3f3, 0 3px 16px #7a8bc6;
  -o-box-shadow: 0px -1px 8px #f3f3f3, 0 3px 16px #7a8bc6;
  box-shadow: 0 2px 8px #f3f3f3, 0 3px 16px #7a8bc6;

  h2 {
    font-size: 18px;
    color: #4369b7;
    padding-top: 24px;
    margin-bottom: 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }

  button {
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    height: 42px;
    margin-bottom: 10px;
    margin-top: 1rem;

    &:hover {
      background-color: #286090;
    }
  }
}
</style>
